.cardOutermost{
    width: 250px!important;
    height:200px!important;
    margin-left:50px;
    margin-top: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.vertIcon{
    font-size: 30px;
    float: right;
    color: #295598;
    z-index: 1000;
}