.outermostCard{
    /* margin-left: 50px; */
    margin-top: 30px;
    background-color: #E1F4FF!important;
    height: 250px;
    width: 300px;
    position: relative;
    /* padding: 0px; */
}

.cardMedia{
    font-size: 55px;
    margin-top: 10px;
    padding:"0px";
}

.AccountCircleIcon{
    font-size: 55px!important;
    color: #295598;
    object-fit: cover;
}

.AvatareName{
    font-size:18px;
    font-weight:bold!important;
}

.buttonContainer{
    display: flex !important;
    width:100%;
    justify-content: center;
    align-items: center;
}

.button{
    width: 120px;
    font-weight: bold!important;
    font-size:10px!important;
    text-transform: unset!important;
}