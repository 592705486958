.textLabels{
    text-align:left;
    font-weight: bold!important;
    font-size: 20px!important;
}
.changeButton{
    text-align:right;
    color: #295598;
    text-decoration: underline;
    font-size: 20px;
    font-weight: bold;
    height: 50px;
}