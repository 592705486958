.textLabels{
    text-align:left;
    font-weight: bold!important;
    font-size: 30px!important;
}
.textInviteHeading{
    text-align:left;
    font-weight: bold!important;
    font-size: 26px!important;
}
.subText{
    text-align: left;
    color:grey;
    font-size:24px;
}
.textField{
    border: none;
    width: 100%;
    border-radius: 3vw;
}
.avatare{
    height:30vh;
    width:15vw;
    border: 1px solid black;
    display:grid;
    align-content: space-around;
    justify-content: center;
}
.outermostDiv{
    display:flex;
    justify-content: flex-start;
    gap:20vh;
    width: 60vw;
    height: 30vh;
}
.contentBox{
    display:flex;
    flex-direction: column;
    gap:6vh;
}
.accountCircleIcon{
    font-size: 200px!important;
    text-align: center!important;
}
.buttonContainer{
    display: flex;
    justify-content: flex-start;
    gap:3vw;
    margin-top: 3vh;
}
.resetButton{
    background-color:#295598!important;
    color: white!important;
    height:5vh;
    width:10vw;
}
.copyButton{
    color:#295598!important;
    border:1px solid #295598!important;
    background-color: white!important;
    height:5vh;
    width:10vw;
}
.copiedIcon{
    transition: "all 10s ease-in-out";
    color: green;
    position: "absolute";
    top: 0;
    left: 0;
    
}