.title{
    font-family: "Gordita";
    font-weight: 500;
    font-size: 24px;
}
.textArea{
    width: 100%;
    background-color: #E1F4FF;
}
.closeButton{
    background-color:white!important;
    color: #295598!important;
    margin-right: 12px!important;
    border: 1px solid black!important;
    width: 7vw;
}
.addAnswerButton{
    background-color: #295598!important;
    color: white!important
}