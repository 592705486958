.chatbox{
    border-radius: 60px;
    width: 70% !important;
}
.table{
    width: 65px;
}
.chatSection{
    width: 100%!important;
    margin-right:0px!important;
    padding-right: 0px!important;
    padding-top: 25px;
    padding-left: 10px;
    padding-right: 10px;
}
.headBG{
    background-color: #e0e0e0;
}
.borderRight500{
    border-right: 1px solid #e0e0e0;
}
.messageArea{
    height: 60vh;
    width: 100%;
    padding: 0;
    background-color: none !important;
    overflow-y: auto;
}
.headerContainer{
    background: linear-gradient(90deg, #4802A2 0%, #9343FA 100%);    
    color: white;
    height: 57px;
    width:100%;
    padding-left: 20px;
    padding-top: 5px;
    border-radius: 16px!important;
}
.accountCircleIcon{
    margin: 20px!important;
    float: left;
    font-size: 30px;
}
.chatAvatareContainer{
    display: flex!important;
    padding: 0;
    justify-content: flex-start;
}
.chatMessageAvatare{
    color: #295598;
    margin-right: 2px;
}
.chatMessageContainer{
    display: flex;
    justify-content: flex-end;
}
.chatMessage{
    display: flex!important;
    background-color: #F2F2F2;
    margin-left: 10px!important;
    margin-right: 0px !important;
    border-radius: 6px;
    padding: 14px!important;
    width: fit-content;
}
.chatMessage2{
    display: flex!important;
    background-color: #fff;
    border:1px solid rgba(78, 78, 78, 0.4);
    margin-left: 10px!important;
    margin-right: 0px !important;
    border-radius: 6px;
    padding: 14px!important;
    width: fit-content;
}
.chatMessageUser{
    display: flex!important;
    background-color: #fff;
    border:1px solid rgba(78, 78, 78, 0.4);
    margin-left: 10px!important;
    margin-right: 12px !important;
    border-radius: 6px;
    padding: 14px!important;
    width: fit-content;

}
.defaultQuestionsList{
    background-color:whitesmoke;
    padding:2px!important;
    margin:2px;
}