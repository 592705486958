.NavBarOutermost{
    width: 100%;
    height: 70px;
    padding-top: 2vh;
    padding-bottom: 2vh;
    background-color: transparent;
    display: flex;
    justify-content: space-around;

}
.BackgroundFirst{
    background-image: url("../../assets/img/organization/LandingTwoBackground.png");
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center;  
}
.NavBarTabText{
    font-size: 16px;
    font-weight: 500;
    font-family: Gordita;
    color: white;
    cursor: pointer;
}
.NavBarInside{
    display: flex;
    justify-content: space-around;
    gap: 2vw;
}
.NavBarTabs{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4vw;
}
.ButtonContainer{
    display:flex;
    justify-content: flex-start;
}
.ButtonOutlined{
    color:white!important;
    padding:10px!important;
    font-weight: 500!important;
    margin-left: 0px!important;

}
.ButtonFilled{
    padding:10px!important;
    background-color: #C91A7B!important;
    color: white!important;
    font-weight: 500!important;
    
}
.ButtonFilledWhite{
    padding:10px!important;
    background-color: white!important;
    color: black!important;
    font-weight: 500!important;
    
}
.WelcomeBox{
    height: 722px;
    width: 100%;
    display: flex!important;
    justify-content: center!important;
}
.WelcomeContent{
    width:80vw;
    gap:15px;
    margin-top: 40px;
}
.WelcomeText{
    margin-top:20vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.TextBoldSmall{
    text-align: center;
    font-family: Gordita!important;
    font-weight: 900!important;
    line-height: 62.7px!important;
    color:white;
}
.TextBoldLarge{
    text-align: center;
    font-size: 44px!important;
    font-family: Gordita!important;
    font-weight: 900!important;
    line-height: 62.7px!important;
}
.TextColored{
    text-align: center;
    color:white;
    font-size: 38px!important;
    font-weight: 900!important;
    line-height: 39.9px!important;
    font-family: Gordita!important;
}
.TextCommon{
    text-align: left;
    font-weight:400!important;
    font-size: 22px!important;
    line-height: 31.35px!important;
}
.ChoiceBox{
    height: 1336px !important;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white!important;

}
.ChoiceContainer{
    display: flex!important;
}
.ReplicateBox{
    display:flex!important;
    margin-top: 158px;
}
.ReplicateContent{
    display: flex!important;
    flex-direction: column;
    width: 40vw;
    margin-left:30px;
}
.LatestDevelopmentsBox{
    background-image: url("../../assets/img/organization/CuttingEdgeBackground.png");
    height: 415px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.LatestTexts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
}
.RetainBox{
    background-color: white;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.RetainText{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.RetainContent{
    display:flex;
    width: 70vw;
    gap:50px;
}
.RespondBox{
    background-color: white;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.RespondText{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.RespondContent{
    display:flex;
    width: 70vw;
    gap:50px;
}
.RetainAndRespond{
    display:flex;
    flex-direction: column;
    gap: 10vh;
    height: 1552px!important;
    padding: 100px 20px 50px;
    background-color: white;
}
.CustomizedDataBox{
    background-color: white;
    height: 1914px;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:7vh;
}
.CustomizedDataText{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}
.CustomizedDataContent{
    width:80vw;
    display:flex;
    gap:50px;
}
.TextBoxFAQ{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.WordBreaks{
    width: 14px;
    height: 14px;
    border-radius: 200px;
    background: #2576B8;
    margin-left: 10px;
    margin-right: 10px;
}
.FooterBox{
    background-color: #101010;
    height:443px;
    width:100%;
    display: flex;
    justify-content: space-evenly;
    gap:3vw;
    padding-top: 30px;
}
.FooterColumn{
    display:flex;
    flex-direction: column;
    gap: 2px;
}
