.outermostCard{
    margin-left:50px;
    margin-top: 50px;
    background-color: #E1F4FF!important;
    height:180px;
    width:280px;
}

.cardMedia{
    margin: 20px auto;
}

.AccountCircleIcon{
    /* padding:20px; */
    height: 55px;
    width: 55px;
    font-size: 55px!important;
    color: #295598;
    border-radius: 50%;
    object-fit: cover;
}

.AvatareName{
    font-size:18px;
    font-weight:bold!important;
}

.buttonContainer{
    display: flex!important;
    flex-direction: row; 
    width:300px!important;
    justify-content: center!important;
    margin-top: 10px;
}
.button{
    width: 120px!important;
    font-weight: bold!important;
    font-size:10px!important;
    text-transform: unset!important;
    margin: 10px 0px;
}