.outermostCard{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 60%;
    height:fit-content;
    background-color: white;
    border-radius: 4px;
    box-shadow: 24;
    padding:20px
}
.divider{
    background: lightgrey;
    color: lightgrey;
    border-color: lightgrey;
    height: 0.1px;
    width: 100%;
}
.textField{
    background-color: #E1F4FF;
    height: 100%;
    width: 100%;
    margin-top: 2;
    justify-content: flex;
    align-items: center;
    flex-direction: center;
    border-style: column;
    border-radius: solid;
    border-color: 1;
}
.buttonContainer{
    margin-top: 30px;
    display: flex!important;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom:30px;
}
