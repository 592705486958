.outermostCard{
     display:flex!important;
    flex-direction: row;
    justify-content: flex-start;
    width: 150vw;
    margin:0px!important;
    padding:0px!important;
    border: 1px solid black;
    margin-top: 23px!important;
    background-color: #E1F4FF;
    cursor: pointer;
}
.SessionId{
    font-family: Gordita!important;
    font-size: 24px;
    padding-top:23px;
    padding-left:23px;
    padding-bottom:23px;
    text-align: left;
}