.NavBarOutermost{
    width: 100%;
    height: 10%;
    padding-top: 2vh;
    padding-bottom: 2vh;
    background-color: white;
    display: flex;
    justify-content: space-around;

}
.NavBarTabText{
    font-size: 16px;
    font-weight: 500;
    font-family: Gordita;
    text-decoration: none;
    color: #000;

}
.NavBarInside{
    display: flex;
    justify-content: space-around;
    gap: 2vw;
}
.NavBarTabs{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4vw;
    text-decoration: none;
    color: black !important;
    border: none;
}
.ButtonContainer{
    display:flex;
    justify-content: flex-start;
}
.ButtonOutlined{
    color:black!important;
    padding:10px 20px !important;
    font-weight: 500!important;
    margin-left: 0px!important;
    text-decoration: none !important;
    

}
.ButtonFilled{
    padding:10px!important;
    background-color: #1F78B4!important;
    color: white!important;
    font-weight: 500!important;
    
}
.WelcomeBox{
    height: 722px;
    width: 100%;
    background-color: #E1F4FF;
    display: flex!important;
    justify-content: center!important;
}
.WelcomeContent{
    display:flex;
    width:80vw;
    gap:15px;
    margin: 40px;
}
.WelcomeText{
    margin-top:20vh;
}
.TextBoldSmall{
    text-align: left;
    font-family: Gordita!important;
    font-weight: 900!important;
    line-height: 62.7px!important;
}
.TextBoldLarge{
    text-align: left;
    font-size: 44px!important;
    font-family: Gordita!important;
    font-weight: 900!important;
    line-height: 62.7px!important;
}
.TextColored{
    text-align: left;
    color:#295598;
    font-size: 28px!important;
    font-weight: 900!important;
    line-height: 39.9px!important;
    font-family: Gordita!important;
}
.TextCommon{
    text-align: left;
    font-weight:400!important;
    font-size: 22px!important;
    line-height: 31.35px!important;
}
.CompaniesBox{
    height: 281px !important;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white!important;

}
.LatestDevelopmentsBox{
    background-color: #E1F4FF;
    height: 609px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.LatestDevelopmentCards{
    display: flex;
    gap:30px;
}
.LatestTexts{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.CustomEmojisBox{
    background-color: white;
    height: 504px;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.CustomEmojisText{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.CustomEmojisContent{
    display:flex;
    width:80vw;
    gap:50px;
}
.CustomizedDataBox{
    background-color: #E1F4FF;
    height: 502px;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.CustomizedDataText{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}
.CustomizedDataContent{
    margin-top: 100px;
    width:80vw;
    display:flex;
    gap:50px;
}
.CloneAvatareBox{
    background-color: white;
    height: 598px;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.CloneAvatareContent{
    margin-top: 100px;
    width:80vw;
    display:flex;
    gap:50px;
}
.CloneAvatareText{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.DocumentationBox{
    background-color: #E1F4FF;
    height: 502px;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.DocumentationText{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}
.DocumentationContent{
    margin-top: 100px;
    width:80vw;
    display:flex;
    gap:50px;
}
.ClarityBox{
    background-color: white;
    height: 598px;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.ClarityContent{
    margin-top: 100px;
    width:80vw;
    display:flex;
    gap:50px;
}
.ClarityText{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.IntegrationBox{
    background-color: #E1F4FF;
    height: 729px;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.IntegrationText{
    display:flex;
    align-items: center;
}
.TrendsBox{
    background-color: white;
    height: 598px;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.TrendsContent{
    margin-top: 100px;
    width:80vw;
    display:flex;
    gap:50px;
}
.TrendsText{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.PaymentBox{
    background-color: #E1F4FF;
    height: 1349px;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:49px;
}
.PaymentText{
    display:flex;
    align-items: center;
}
.CardBox{
    display: flex;
    gap:3vw;
}
.FAQBox{
    background-color: white;
    height: 985px;
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    align-items: center;

}
.FAQContent{
    margin-top: 100px;

}
.FAQIcon{

}
.FAQText{
    display: flex!important;
    justify-content: center;

}
.Footer{
    background-color: #295598;
    width: 100%;
    height: 96px;
    display:flex!important;
    /* padding: 10px; */
    justify-content: space-around;
    margin-top: 180px;
}
.FooterText{
    color: white;
    font-size: 22px!important;
    font-weight: 500!important;
    margin-top:33px!important;
    margin-bottom:33px!important;
    line-height: 31.35px;
}