.Title{
    font-size: 20px!important;
    font-weight: 500!important;
    line-height: 39px!important;
    text-align: center!important;
    margin-bottom:14px;
}
.CardContent{
    margin:10px 18px 68px 18px
}
.CardText{
    text-align: left;
}
