.rcw-message {
    margin: 10px;
    display: flex;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  .rcw-message-client {
    flex-direction: row-reverse;
  }
  .rcw-timestamp {
    font-size: 10px;
    margin-top: 5px;
  }
  .rcw-client {
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }
  .rcw-client .rcw-message-text {
    background-color: #f2f2f2;
    border-radius: 10px;
    max-width: 215px;
    padding: 15px;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  .rcw-client .rcw-timestamp {
    align-self: flex-end;
  }
  .rcw-response {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .rcw-response .rcw-message-text {
    background-color: #f4f7f9;
    border-radius: 10px;
    max-width: 215px;
    padding: 15px;
    text-align: left;
  }
  .rcw-message-text p {
    margin: 0;
  }
  .rcw-message-text img {
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }
  .rcw-avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
  }
  .rcw-avatar-client {
    margin: 0 0 0 10px;
  }
  .rcw-snippet {
    background-color: #f4f7f9;
    border-radius: 10px;
    max-width: 215px;
    padding: 15px;
    text-align: left;
  }
  .rcw-snippet-title {
    margin: 0;
  }
  .rcw-snippet-details {
    border-left: 2px solid #35e65d;
    margin-top: 5px;
    padding-left: 10px;
  }
  .rcw-link {
    font-size: 12px;
  }
  .quick-button {
    background: none;
    border-radius: 15px;
    border: 2px solid #497964;
    font-weight: 700;
    padding: 5px 10px;
    cursor: pointer;
    outline: 0;
  }
  .quick-button:active {
    background: #497964;
    color: #fff;
  }
  .rcw-launcher{
    
    background: #497964 !important;
    display:flex;
    justify-content: center;
    align-items: center;

  }
  .rcw-conversation-container .rcw-header {
    background-color: #497964;
    border-radius: 10px 10px 0 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 15px 0 25px;
  }
  .rcw-conversation-container .rcw-title {
    font-size: 24px;
    margin: 0;
    padding: 15px 0;
  }
  .rcw-conversation-container .rcw-close-button {
    display: none;
  }
  .rcw-conversation-container .avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
    vertical-align: middle;
  }
  .rcw-full-screen .rcw-header {
    border-radius: 0;
    flex-shrink: 0;
    position: relative;
  }
  .rcw-full-screen .rcw-title {
    padding: 0 0 15px;
  }
  .rcw-full-screen .rcw-close-button {
    background-color: #497964;
    border: 0;
    display: block;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 40px;
  }
  .rcw-full-screen .rcw-close {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 800px) {
    .rcw-conversation-container .rcw-header {
      border-radius: 0;
      flex-shrink: 0;
      position: relative;
    }
    .rcw-conversation-container .rcw-title {
      padding: 0 0 15px;
    }
    .rcw-conversation-container .rcw-close-button {
      background-color: #497964;
      border: 0;
      display: block;
      position: absolute;
      right: 10px;
      top: 20px;
      width: 40px;
    }
    .rcw-conversation-container .rcw-close {
      width: 20px;
      height: 20px;
    }
  }
  .loader {
    margin: 10px;
    display: none;
  }
  .loader.active {
    display: flex;
  }
  .loader-container {
    background-color: #f4f7f9;
    border-radius: 10px;
    padding: 15px;
    max-width: 215px;
    text-align: left;
  }
  .loader-dots {
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: grey;
    margin-right: 2px;
    -webkit-animation: bounce 0.5s ease infinite alternate;
    animation: bounce 0.5s ease infinite alternate;
  }
  .loader-dots:first-child {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .loader-dots:nth-child(2) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .loader-dots:nth-child(3) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  @-webkit-keyframes bounce {
    0% {
      transform: translateY(0);
    }
    to {
      transform: translateY(5px);
    }
  }
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    to {
      transform: translateY(5px);
    }
  }
  .rcw-messages-container {
    background-color: #fff;
    height: 50vh;
    max-height: 410px;
    overflow-y: scroll;
    padding-top: 10px;
    -webkit-overflow-scrolling: touch;
  }
  .rcw-full-screen .rcw-messages-container {
    height: 100%;
    max-height: none;
  }
  @media screen and (max-width: 800px) {
    .rcw-messages-container {
      height: 100%;
      max-height: none;
    }
  }
  .rcw-sender {
    align-items: flex-end;
    background-color: #f4f7f9;
    border-radius: 0 0 10px 10px;
    display: flex;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    max-height: 95px;
    min-height: 45px;
    overflow: hidden;
    padding: 10px;
    position: relative;
  }
  .rcw-sender.expand {
    height: 55px;
  }
  .rcw-new-message {
    background-color: #fff;
    border: 0;
    border-radius: 5px;
    padding: 10px 5px;
    resize: none;
    width: calc(100% - 75px);
  }
  .rcw-new-message:focus {
    outline: none;
  }
  .rcw-new-message.expand {
    height: 40px;
  }
  .rcw-input {
    display: block;
    height: 100%;
    line-height: 20px;
    max-height: 78px;
    overflow-y: auto;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  .rcw-input.focus-visible,
  .rcw-input:focus-visible {
    outline: none;
  }
  .rcw-input[placeholder]:empty:before {
    content: attr(placeholder);
    color: grey;
  }
  .rcw-picker-btn,
  .rcw-send {
    background: #f4f7f9;
    border: 0;
    cursor: pointer;
  }
  .rcw-picker-btn .rcw-send-icon,
  .rcw-send .rcw-send-icon {
    height: 25px;
  }
  .rcw-message-disable {
    background-color: #f4f7f9;
    cursor: not-allowed;
  }
  @media screen and (max-width: 800px) {
    .rcw-sender {
      border-radius: 0;
      flex-shrink: 0;
    }
  }
  .quick-buttons-container {
    background: #fff;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
  }
  .quick-buttons-container .quick-buttons {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .quick-buttons-container .quick-buttons .quick-list-button {
    display: inline-block;
    margin-right: 10px;
  }
  @media screen and (max-width: 800px) {
    .quick-buttons-container {
      padding-bottom: 15px;
    }
  }
  .emoji-mart,
  .emoji-mart * {
    box-sizing: border-box;
    line-height: 1.15;
  }
  .emoji-mart {
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, sans-serif;
    font-size: 16px;
    display: inline-block;
    color: #222427;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    background: #fff;
  }
  .emoji-mart .emoji-mart-emoji {
    padding: 6px;
  }
  .emoji-mart-bar {
    border: 0 solid #d9d9d9;
  }
  .emoji-mart-bar:first-child {
    border-bottom-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .emoji-mart-bar:last-child {
    border-top-width: 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .emoji-mart-anchors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 6px;
    line-height: 0;
  }
  .emoji-mart-anchor {
    position: relative;
    display: block;
    flex: 1 1 auto;
    color: #858585;
    text-align: center;
    padding: 12px 4px;
    overflow: hidden;
    transition: color 0.1s ease-out;
    margin: 0;
    box-shadow: none;
    background: none;
    border: none;
  }
  .emoji-mart-anchor:focus {
    outline: 0;
  }
  .emoji-mart-anchor-selected,
  .emoji-mart-anchor:focus,
  .emoji-mart-anchor:hover {
    color: #464646;
  }
  .emoji-mart-anchor-selected .emoji-mart-anchor-bar {
    bottom: 0;
  }
  .emoji-mart-anchor-bar {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #464646;
  }
  .emoji-mart-anchors i {
    display: inline-block;
    width: 100%;
    max-width: 22px;
  }
  .emoji-mart-anchors img,
  .emoji-mart-anchors svg {
    fill: currentColor;
    height: 18px;
    width: 18px;
  }
  .emoji-mart-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 270px;
    padding: 0 6px 6px;
    will-change: transform;
  }
  .emoji-mart-search {
    margin-top: 6px;
    padding: 0 6px;
    position: relative;
  }
  .emoji-mart-search input {
    font-size: 16px;
    display: block;
    width: 100%;
    padding: 5px 25px 6px 10px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    outline: 0;
  }
  .emoji-mart-search input,
  .emoji-mart-search input::-webkit-search-cancel-button,
  .emoji-mart-search input::-webkit-search-decoration,
  .emoji-mart-search input::-webkit-search-results-button,
  .emoji-mart-search input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .emoji-mart-search-icon {
    position: absolute;
    top: 7px;
    right: 11px;
    z-index: 2;
    padding: 2px 5px 1px;
    border: none;
    background: none;
  }
  .emoji-mart-category .emoji-mart-emoji span {
    z-index: 1;
    position: relative;
    text-align: center;
    cursor: default;
  }
  .emoji-mart-category .emoji-mart-emoji:hover:before {
    z-index: 0;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    border-radius: 100%;
  }
  .emoji-mart-category-label {
    z-index: 2;
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  .emoji-mart-category-label span {
    display: block;
    width: 100%;
    font-weight: 500;
    padding: 5px 6px;
    background-color: #fff;
    background-color: hsla(0, 0%, 100%, 0.95);
  }
  .emoji-mart-category-list {
    margin: 0;
    padding: 0;
  }
  .emoji-mart-category-list li {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
  }
  .emoji-mart-emoji {
    position: relative;
    display: inline-block;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    box-shadow: none;
  }
  .emoji-mart-emoji-native {
    font-family: Segoe UI Emoji, Segoe UI Symbol, Segoe UI, Apple Color Emoji,
      Twemoji Mozilla, Noto Color Emoji, Android Emoji;
  }
  .emoji-mart-no-results {
    font-size: 14px;
    text-align: center;
    padding-top: 70px;
    color: #858585;
  }
  .emoji-mart-no-results-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .emoji-mart-no-results .emoji-mart-category-label {
    display: none;
  }
  .emoji-mart-no-results .emoji-mart-no-results-label {
    margin-top: 0.2em;
  }
  .emoji-mart-no-results .emoji-mart-emoji:hover:before {
    content: none;
  }
  .emoji-mart-preview {
    position: relative;
    height: 70px;
  }
  .emoji-mart-preview-data,
  .emoji-mart-preview-emoji,
  .emoji-mart-preview-skins {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .emoji-mart-preview-emoji {
    left: 12px;
  }
  .emoji-mart-preview-data {
    left: 68px;
    right: 12px;
    word-break: break-all;
  }
  .emoji-mart-preview-skins {
    right: 30px;
    text-align: right;
  }
  .emoji-mart-preview-skins.custom {
    right: 10px;
    text-align: right;
  }
  .emoji-mart-preview-name {
    font-size: 14px;
  }
  .emoji-mart-preview-shortname {
    font-size: 12px;
    color: #888;
  }
  .emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon,
  .emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
  .emoji-mart-preview-shortname + .emoji-mart-preview-shortname {
    margin-left: 0.5em;
  }
  .emoji-mart-preview-emoticon {
    font-size: 11px;
    color: #bbb;
  }
  .emoji-mart-title span {
    display: inline-block;
    vertical-align: middle;
  }
  .emoji-mart-title .emoji-mart-emoji {
    padding: 0;
  }
  .emoji-mart-title-label {
    color: #999a9c;
    font-size: 26px;
    font-weight: 300;
  }
  .emoji-mart-skin-swatches {
    font-size: 0;
    padding: 2px 0;
    border: 1px solid #d9d9d9;
    border-radius: 12px;
    background-color: #fff;
  }
  .emoji-mart-skin-swatches.custom {
    font-size: 0;
    border: none;
    background-color: #fff;
  }
  .emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
    width: 16px;
    padding: 0 2px;
  }
  .emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
    opacity: 0.75;
  }
  .emoji-mart-skin-swatch {
    display: inline-block;
    width: 0;
    vertical-align: middle;
    transition-property: width, padding;
    transition-duration: 0.125s;
    transition-timing-function: ease-out;
  }
  .emoji-mart-skin-swatch:first-child {
    transition-delay: 0s;
  }
  .emoji-mart-skin-swatch:nth-child(2) {
    transition-delay: 0.03s;
  }
  .emoji-mart-skin-swatch:nth-child(3) {
    transition-delay: 0.06s;
  }
  .emoji-mart-skin-swatch:nth-child(4) {
    transition-delay: 0.09s;
  }
  .emoji-mart-skin-swatch:nth-child(5) {
    transition-delay: 0.12s;
  }
  .emoji-mart-skin-swatch:nth-child(6) {
    transition-delay: 0.15s;
  }
  .emoji-mart-skin-swatch.selected {
    position: relative;
    width: 16px;
    padding: 0 2px;
  }
  .emoji-mart-skin-swatch.selected:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    margin: -2px 0 0 -2px;
    background-color: #fff;
    border-radius: 100%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }
  .emoji-mart-skin-swatch.custom {
    display: inline-block;
    width: 0;
    height: 38px;
    overflow: hidden;
    vertical-align: middle;
    transition-property: width, height;
    transition-duration: 0.125s;
    transition-timing-function: ease-out;
    cursor: default;
  }
  .emoji-mart-skin-swatch.custom.selected {
    position: relative;
    width: 36px;
    height: 38px;
    padding: 0 2px 0 0;
  }
  .emoji-mart-skin-swatch.custom.selected:after {
    content: "";
    width: 0;
    height: 0;
  }
  .emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
    background-color: #f4f4f4;
    border-radius: 10%;
  }
  .emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
    width: 36px;
    height: 38px;
    padding: 0 2px 0 0;
  }
  .emoji-mart-skin-swatches.custom.opened
    .emoji-mart-skin-swatch.custom.selected:after {
    opacity: 0.75;
  }
  .emoji-mart-skin-text.opened {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    color: #888;
    font-size: 11px;
    padding: 5px 2px;
    width: 95px;
    height: 40px;
    border-radius: 10%;
    background-color: #fff;
  }
  .emoji-mart-skin {
    display: inline-block;
    width: 100%;
    padding-top: 100%;
    max-width: 12px;
    border-radius: 100%;
  }
  .emoji-mart-skin-tone-1 {
    background-color: #ffc93a;
  }
  .emoji-mart-skin-tone-2 {
    background-color: #fadcbc;
  }
  .emoji-mart-skin-tone-3 {
    background-color: #e0bb95;
  }
  .emoji-mart-skin-tone-4 {
    background-color: #bf8f68;
  }
  .emoji-mart-skin-tone-5 {
    background-color: #9b643d;
  }
  .emoji-mart-skin-tone-6 {
    background-color: #594539;
  }
  .emoji-mart-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .emoji-mart-dark {
    color: #fff;
    background-color: #222;
  }
  .emoji-mart-dark,
  .emoji-mart-dark .emoji-mart-bar {
    border-color: #555453;
  }
  .emoji-mart-dark .emoji-mart-search input {
    color: #fff;
    border-color: #555453;
    background-color: #2f2f2f;
  }
  .emoji-mart-dark .emoji-mart-search-icon svg {
    fill: #fff;
  }
  .emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
    background-color: #444;
  }
  .emoji-mart-dark .emoji-mart-category-label span {
    background-color: #222;
    color: #fff;
  }
  .emoji-mart-dark .emoji-mart-skin-swatches {
    border-color: #555453;
    background-color: #222;
  }
  .emoji-mart-dark .emoji-mart-anchor-selected,
  .emoji-mart-dark .emoji-mart-anchor:focus,
  .emoji-mart-dark .emoji-mart-anchor:hover {
    color: #bfbfbf;
  }
  .rcw-conversation-container {
    border-radius: 10px;
    box-shadow: 0 2px 10px 1px #b5b5b5;
    min-width: 370px;
    max-width: 370px;
    position: relative;
  }
  .rcw-conversation-container.active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  .rcw-conversation-container.hidden {
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  .rcw-conversation-resizer {
    cursor: col-resize;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 5px;
  }
  .emoji-mart-preview {
    display: none;
  }
  .rcw-full-screen .rcw-conversation-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  @media screen and (max-width: 800px) {
    .rcw-conversation-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .rcw-launcher .rcw-badge {
    position: fixed;
    top: -10px;
    right: -5px;
    background-color: red;
    color: #fff;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
  }
  .rcw-launcher {
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-name: slide-in;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: 0.5s;
    -moz-animation-name: slide-in;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: 0.5s;
    animation-name: slide-in;
    animation-fill-mode: forwards;
    align-self: flex-end;
    background-color: #35cce6;
    border: 0;
    border-radius: 50%;
    box-shadow: 0 2px 10px 1px #b5b5b5;
    height: 60px;
    margin-top: 10px;
    cursor: pointer;
    width: 60px;
  }
  .rcw-launcher:focus {
    outline: none;
  }
  .rcw-open-launcher {
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-name: rotation-rl;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: 0.5s;
    -moz-animation-name: rotation-rl;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: 0.5s;
    animation-name: rotation-rl;
    animation-fill-mode: forwards;
  }
  .rcw-close-launcher {
    width: 20px;
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-name: rotation-lr;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: 0.5s;
    -moz-animation-name: rotation-lr;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: 0.5s;
    animation-name: rotation-lr;
    animation-fill-mode: forwards;
  }
  @media screen and (max-width: 800px) {
    .rcw-launcher {
      bottom: 0;
      margin: 20px;
      position: fixed;
      right: 0;
    }
    .rcw-hide-sm {
      display: none;
    }
  }
  .rcw-previewer-container {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
  }
  .rcw-previewer-container .rcw-previewer-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.3s ease;
  }
  .rcw-previewer-container .rcw-previewer-tools {
    position: fixed;
    right: 16px;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .rcw-previewer-container .rcw-previewer-button {
    padding: 0;
    margin: 16px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    background-color: #fff;
    border: none;
  }
  .rcw-previewer-container .rcw-previewer-close-button {
    position: absolute;
    right: 0;
    top: 0;
  }
  .rcw-previewer-container .rcw-previewer-veil {
    width: 100%;
    height: 100%;
    overflow: scroll;
    position: relative;
  }
  @-webkit-keyframes rotation-lr {
    0% {
      transform: rotate(-90deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes rotation-lr {
    0% {
      transform: rotate(-90deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes rotation-rl {
    0% {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes rotation-rl {
    0% {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes slide-in {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes slide-in {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @-webkit-keyframes slide-out {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(10px);
    }
  }
  @keyframes slide-out {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(10px);
    }
  }
  .rcw-widget-container {
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 0;
    position: fixed;
    right: 0;
    z-index: 9999;
  }
  .rcw-full-screen {
    height: 100vh;
    margin: 0;
    max-width: none;
    width: 100%;
  }
  @media screen and (max-width: 800px) {
    .rcw-widget-container {
      height: 100%;
      height: 100vh;
      margin: 0;
      max-width: none;
      width: 100%;
    }
  }
  .rcw-previewer .rcw-message-img {
    cursor: pointer;
  }
  .rcw-close-widget-container {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  