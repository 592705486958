/* .App {
  text-align: center;
  flex-wrap: wrap;
  font-family: Gordita !important;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@font-face {
  font-family: "Gordita" !important;
  src: url("./assets/fonts/GorditaRegular.otf");
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.badge span {
  background-color: #524e4e !important;
}

.Link {
  text-decoration: none;
  color: black;
}
.swal-modal {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 25%; */
}
.swal-overlay {
  /* height:1000px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swal-button {
  background-color: #295598 !important;
}
.swal-text {
  text-align: center;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.customButton {
  border-color: #295598 !important;
  color: #295598 !important;
}

.loading-spinner {
  width: 70px;
  height: 70px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #154d27; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.spinner-container{
  height: 70px !important;
  width: 70px !important;
  margin: 0px !important;
  padding: 0px !important;

}

.CreditCardInfo_container__9boBd{
  /* display:flex !important;
  gap: 20px !important; */
  margin: 40px 0px;
  width: 100% !important;
  flex-direction: column !important;
  justify-content: start !important;
  text-align: start;
  align-items: center !important;
  flex-wrap: wrap !important;
  height: 100%;
}
