.outermostCard{
    background-color: #1D4D6A;
    position: fixed;
    bottom: 0vh;
    right: 0px;
    display: flex!important;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 80% !important;
    height: 75px;
}
.triangle{
    padding: 0;
    margin: 0;
    height: 0;
    width: 0;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 70px solid white;
}
.dataBox{
    width:30vw;
    height:75px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    padding-top: 10px;
    padding-left: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.dataBox2{
    width:30vw;
    height:75px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #E1F4FF;
    padding-top: 10px;
    padding-left: 10px;
    left: 0;
}
.nextBox{
    width:150px;
    height:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center; 
    background-color: white;
    cursor: pointer;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.textStyles{
    font-weight: bold;
    font-size: 16px;
    margin: 10px;
}