.Card{
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    width: 340px;
    height: 326px;
    border-radius: 10px;
    margin-top: 62px;
}

.CardTitle{
    font-size: 23px!important;
    color: #295598;
    font-weight: 700;
    line-height: 32.77px;
    font-family: Gordita;
    padding: 20px;
}
.TextCommon{
    padding: 14px 26px 0;
    text-align: center;
    font-weight:400!important;
    font-size: 16px!important;
    line-height: 29px!important;
}
.ButtonFilled{
    padding:10px!important;
    background-color: #1F78B4!important;
    color: white!important;
    font-weight: 500!important;
    border-radius: 5px;
}
.ButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}