.outermostContainer{
    display:flex!important;
    flex-direction: row;
    justify-content: flex-start;
    width: 150vw;
    margin:0px!important;
    padding:0px!important
}
.outermostCard{
    width: 150vw;
    height: fit-content;
    border: 1px solid black;
    margin-top: 23px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
}
.questionStyles{
    font-family: Gordita!important;
    font-size: 24px;
    padding-top:22px;
    padding-bottom:13px;
    text-align: left;
}
.answerStyles{
    font-family: Gordita!important;
    font-size: 24px;
    padding-top:2px;
    padding-left:23px;
    padding-bottom:23px;
    text-align: left;
}
.answerHeading{
    font-family: Gordita!important;
    font-size: 24px;
    padding-top:2px;
    align-items:"row"
}
.activeStyle{
    width: 150vw;
    height: fit-content;
    border: 1px solid black;
    margin-top: 23px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #E1F4FF;
}
.shareBetter{
    background-color: #295598!important;
    color: #ffffff;
}
.shareBetter:hover{
    background-color: #295598!important;
    color: #ffffff;
}
.submitRanks{
    background-color: transparent!important;
    border: solid!important;
    border-color: #295598;
    color: #295598!important;
}
.submitRanks:hover{
    background-color: transparent!important;
    border: solid;
    border-color: #295598;
    color: #295598!important;
}



.buttonContainer{
    display:flex!important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap:15px;
    margin-left:24px;
    margin-bottom: 31px;
}