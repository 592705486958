.cardOutermost{
    max-width: 200vw;
    max-height: 35vh;
    background-color: #E1F4FF!important;
    display:flex!important;
    justify-content:  center;
    align-content: center;
}
.cardOutermostClick{
    max-width: 200vw;
    max-height: 35vh;
    background-color: #f4fafd!important;
    display:flex!important;
    justify-content:  center;
    align-content: center;
}
.cardMedia{
    background-color: #193445;
    font-size: 10px;
    width: 10vw;
    height:10vh;
    display:flex!important;
    justify-content:  center;
    align-content: center;
}
.youtubeIcon{
    color: white;
    margin: 3vh;
}
.cardActionArea{
    display: flex!important;
    flex-direction: row!important;
    padding:10px!important;
}
.cardContent{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}