.outermostCard{
    width: 250px; 
    height:220px; 
    margin-top: 30px;   
    border: dashed;
    border-color: grey;
    background-color: white;
}
.cardMedia{
    font-size:40px;
    display:"flex";
    justify-content: center;
    align-items: center;
    margin-left: 25px;
  }
  .cardMediaanayltics{
    font-size:40px;
    display:"flex";
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
  .cardMediaTraimModel{
    font-size:40px;
    display:"flex";
    justify-content: center;
    align-items: center;
    /* margin-left: 30px ; */
    /* margin: 0px auto; */
}
.AccountCircleIcon{
    font-size: 55px !important;
    color: #295598;
    height: 55px ;
    width: 55px;
    object-fit: cover;
}
.cardContent{
    /* margin:0;
    padding:0; */
    /* position: relative; */
}
.button{
    /* margin-top: 5px!important; */
/* position: absolute;
bottom: 0px; */
    font-weight:  bold;
    font-size: 10px!important;
    background-color:  #295598!important;

}
.vertIcon{
    font-size: 30px;
    float: right;
    color: #295598;
    z-index: 1000;
}

.loaderImage{
    display:block;
    animation: loaderImage 1.5s linear infinite;
}

@keyframes loaderImage {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .bannerCompleted{
    position: relative;
    height:40px;
    transform: rotate(-45deg);
    text-align:start;
    top: 2px;
    right: 61px;
    padding: 5px 50px; 
    color: white;
    background-color: rgb(18, 81, 29);
  }
  .bannerTraining{
    position: relative;
    height:40px;
    transform: rotate(-45deg);
    text-align:left;
    top: 2px;
    right: 50px;
    padding: 5px 50px; 
    color: white;
    background-color: rgb(235, 101, 17);
  }
  .bannerStart{
    position: relative;
    height:40px;
    transform: rotate(-45deg);
    text-align:left;
    top: 2px;
    right: 65px;
    padding: 5px 50px; 
    color: white;
    background-color: rgb(235, 17, 17);
  }