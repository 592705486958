
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Museo Sans";
  src:url("./assets/fonts/Museo-font/Museo-400.ttf")
 }

 *{
  font-family: "Museo Sans" !important;
  margin: 0px ;
}
.custom-arrow {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
body{
  background-color: #F7F8FC;
}
.ant-progress .ant-progress-line .ant-progress-inner {
  padding: 0 !important;
  vertical-align: top !important;
}
.custom-arrow::before {
  content: "";
  position: absolute;
  top: -5px; 
  left: -5px; 
  width: calc(100% + 10px); /* Adjust as needed */
  height: calc(100% + 10px); /* Adjust as needed */
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  border: 5px solid black; /* Adjust as needed */
  z-index: -1; /* Place it behind the arrow */
}

.hover-effect {
  transition: background-color 0.3s ease;
}

.hover-effect:hover
 {
  background-color: rgba(255, 255, 255, 0.10) !important;
}

.hover-effect-inner {
  transition: background-color 0.3s ease;
}

.hover-effect-inner:hover,
.hover-effect-inner:active {
 
  background-color: rgba(255, 255, 255, 0.20) !important;
}

/* src/styles.css */
.custom-steps .ant-steps-item-tail {
  border-color: #8b5cf6 !important; /* Tailwind purple-500 color */
}

.custom-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  background-color: #8b5cf6; /* Tailwind purple-500 color */
  border-color: #8b5cf6;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.custom-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  background-color: #ffffff;
  border-color: #8b5cf6;
  color: #8b5cf6;
}

.custom-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  background-color: #ffffff;
  border-color: #d1d5db; /* Tailwind gray-300 color */
  color: #d1d5db;
}



.sidebar {
  overflow: auto; /* Hides the scrollbar */
}

.sidebar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.sidebar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}
.primary{
  background: var(--Kiwi, #7FB04F);
  text-transform: uppercase;
  color:white;
  
}
.secondary{
text-transform: uppercase;
color:black;
background: white;
border: 1px solid var(--Kiwi, #7FB04F);
}

.sidebar-section-text{
  color:#C6C3C7
}


.custom-btn:hover,
.custom-btn:focus,
.custom-btn:active {
  background-color: var(--Kiwi, #7FB04F) !important ; /* Keeps the background unchanged */
  box-shadow: none !important; /* Removes hover shadow */
  border-color: inherit !important; /* Keeps the border unchanged */
}

.custom-outline:focus{
  outline: none;
  box-shadow: 0 0 0 1px #7FB04F;
}
.link-color{
  color:var(--Fern, #497964);
}
.bg-gradiant{
  background: linear-gradient(90deg, #497964 0%, #7FB04F 100%)

}
.custom-spin .ant-spin-dot-item {
  background:linear-gradient(0deg, #497964 1.56%, #20556E 99.7%);
}
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:hover {
    border-color: #7FB04F !important; /* Change this to your desired color */
    /* Optional: change outline */
}

/* Override the default styles for Ant Pagination */
.ant-pagination-item-active a {
  color: #497964 !important; /* Active page number color */
}

.ant-pagination-item a {
  color: #497964 !important; /* Default page number color */
}

.ant-pagination-item:hover a {
  color: #497964 !important; /* Hover color */
}

.ant-pagination-item-active {
  border-color: #497964 !important; /* Active item border color */
}

.ant-pagination-item:hover {
  border-color: #497964 !important; /* Hover item border color */
}

.ant-pagination-next, .ant-pagination-prev {
  color: #497964 !important; /* Color for next/previous buttons */
}


.ellipsis-anim span {
  opacity: 0;
  -webkit-animation: ellipsis-dot 1s infinite;
  animation: ellipsis-dot 1s infinite;
}
.ellipsis-anim span:nth-child(1) {
  -webkit-animation-delay: 0.0s;
  animation-delay: 0.0s;
}
.ellipsis-anim span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.ellipsis-anim span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
@-webkit-keyframes ellipsis-dot {
    0% { opacity: 0; }
   50% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes ellipsis-dot {
    0% { opacity: 0; }
   50% { opacity: 1; }
  100% { opacity: 0; }
}

.ant-steps-item-icon  {
  background-color: lightgreen !important; 
  border-color: lightgreen !important; 
  color:lightgreen !important
}

.ant-steps-item-tail::after {
  background-color: lightgreen !important; 
}



.ant-steps-item-process .ant-steps-item-icon {
  background-color: #497964 !important; 
  border-color: #497964 !important; 
}


.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #497964 !important; 
}


.ant-steps-item-process .ant-steps-icon-dot {
  background-color: #497964 !important; 
  
}


.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #497964 !important; 
  border-color: #497964 !important; 
  color:#497964 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: lightgreen !important; /* Inner icon of the completed steps */
} 

.ant-steps-item-finish .ant-steps-icon-dot {
  background-color: #497964 !important; 
  
}





.bg-tracker-line-1{
  background: linear-gradient(90deg, #6CA6C4 0%, #20556E 100%)
}

.bg-tracker-circle-1{
  background: linear-gradient(180deg, #4489C5 0%, #79B9F2 100%);

}
.bg-tracker-line-2{
  background:linear-gradient(90deg, #7FB04F 0%, #497964 100%)
}
.bg-tracker-circle-2{
  background: var(--Kiwi, #7FB04F);
}
.bg-tracker-circle-3{
  background: var(--CERULEAN, #20556E)
}

.bg-tracker-arrow-1{
  background: linear-gradient(90deg, #6CA6C4 0%, #20556E 100%)
}
.bg-tracker-arrow-2{
  background: linear-gradient(90deg, #7FB04F 0%, #497964 100%)
}
