.textLabels{
    text-align:left;
    font-weight: bold!important;
    font-size: 20px!important;
}
.textField{
    border: none;
    width: 30vw;
    border-radius: 3vw;
}
.avatare{
    /* height:30vh; */
    /* width:15vw; */
    display:grid;
    align-content: space-around;
    justify-content: center;
}
.outermostDiv{
    display:flex;
    justify-content: flex-start;
    gap:20vh;
    /* width: 60vw; */
    /* height: 30vh; */
}
.contentBox{
    display:flex;
    flex-direction: column;
    gap:6vh;
}
.accountCircleIcon{
    border: 1px solid black;
    font-size: 200px!important;
    text-align: center!important;
    width: 200px;
    height: 200px;
    object-fit: cover;
}
.updateButton{
    background-color:#295598!important;
    color: white!important;
    height:5vh;
    margin-top: 40px !important;
    display: flex;
    width: 100%;
}